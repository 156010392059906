import React, { ReactElement } from 'react';
import { InputText } from 'primereact/inputtext';
import { Controller, ValidationRule, Validate, FieldValues, Control } from 'react-hook-form';
import Language from '@Components/Language';

interface InputTextFieldProps {
  control: Control | any;
  label?: string | ReactElement;
  type?: string;
  name: string;
  id?: string;
  dusk?: string;
  required?: string | boolean;
  pattern?: ValidationRule<RegExp> | undefined;
  validate?: Validate<any, FieldValues> | Record<string, Validate<any, FieldValues>> | undefined;
  disabled?: boolean;
  onChangeParent?: (value: string) => void;
  className?: string;
  placeholder?: string;
  readonly?: boolean;
  pt?: any;
}

export default function InputTextField(props: InputTextFieldProps) {
  const {
    control,
    label,
    name,
    id = name,
    dusk = id,
    onChangeParent,
    type = 'text',
    required = false,
    pattern,
    validate,
    disabled,
    className,
    placeholder,
    readonly = false,
    pt,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        pattern,
        validate,
        required,
      }}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error: errorController } }) => {
        const helperText = errorController ? errorController?.message : null;
        return (
          <>
            {label && (
              <label htmlFor={name} className={`${helperText ? 'p-error' : ''}`}>
                {label}
                {required && <span className={`text-red-800 ${helperText ? 'p-invalid' : ''}`}>&nbsp;*</span>}
              </label>
            )}

            <InputText
              id={name}
              name={name}
              type={type}
              data-dusk={dusk ?? id}
              disabled={disabled}
              ref={ref}
              placeholder={placeholder || Language.get(`Insert ${label}`)}
              value={value ?? ''}
              onChange={(event) => {
                onChangeParent && onChangeParent(event.target.value);
                onChange(event);
              }}
              pt={pt}
              onBlur={onBlur}
              readOnly={readonly}
              className={`${className ?? ''} ${helperText ? 'p-invalid' : ''}
                border-none border-bottom-1 border-500 border-noround surface-ground mt-2 w-full`}
            />

            {helperText && <small className="p-error">{helperText}</small>}
          </>
        );
      }}
    />
  );
}
